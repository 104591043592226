import React, {useEffect} from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Image, Nav, Navbar, Container, Row, Col } from "react-bootstrap/";
import Scrollspy from "react-scrollspy";

import "font-awesome/css/font-awesome.min.css";


var hScrollTimer:NodeJS.Timeout|null = null;

function App() {
	//const [DisableWellbore, setDisableWellbore] = useState(true);
	console.log("app");

	useEffect(() => {
		const ResetScrollTimer = ()=> {
			if(hScrollTimer) {
				clearTimeout(hScrollTimer);
				hScrollTimer = null;
			}
			hScrollTimer = setTimeout(() => {
				console.log("ScrollDone");
				//setDisableWellbore(false);
			}, 250);
		}

		const handleScroll = () => {
			if(window.pageYOffset > 1) {
				//setScrolled(true)
				console.log("scroll");
				//if(DisableWellbore===false) setDisableWellbore(true);
				ResetScrollTimer();
			} else {
				//setScrolled(false)
				console.log("NON scroll");
			}
		}
		window.addEventListener("scroll", handleScroll)
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	return(
		<div className="App" data-spy="scroll" data-target="#main" data-offset="0">
			<Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
				<Navbar.Brand href="#" style={{fontVariantCaps:"petite-caps"}}>Wansco</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav id="main" className="mr-auto">
						<Scrollspy items={ ["projects", "technologies", "about", "contact"] } currentClassName="is-current" className="scrollspynav">
							<li><Nav.Link href="#projects">Projects</Nav.Link></li>
							<li><Nav.Link href="#technologies">Technologies</Nav.Link></li>
							<li><Nav.Link href="#about">About</Nav.Link></li>
							<li><Nav.Link href="#contact">Contact</Nav.Link></li>
						</Scrollspy>
						{/*
						<NavDropdown title="Dropdown" id="collasible-nav-dropdown">
							<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
						</NavDropdown>
						*/}
					</Nav>
					<Nav>
						{/*
						<Nav.Link href="#deets">More deets</Nav.Link>
						<Nav.Link eventKey={2} href="#memes">
							Dank memes
						</Nav.Link>
						*/}
						<Nav.Item><Image src="images/w_32.png"></Image></Nav.Item>
					</Nav>
				</Navbar.Collapse>
			</Navbar>


			<div>
				<header className="App-header">
					<div className="App-logo-container">
						<div className="App-logo-overlay">
							<div className="devCycleText">Wansco<br/>Development<br/>Cycle</div>
						</div>
						<img src={logo} className="App-logo" alt="logo" />
					</div>
					<Container>
						<Row>
							<Col>
								<p>
									Specializing in software &amp; hardware design. We do everything from prototyping to turn-key solutions.
								</p>
								<p>
									Scroll down to learn more about what we do, or <a className="App-link" href="#contact">contact us</a> and let's build something cool.
								</p>
							</Col>
						</Row>
						<Image src="images/animated-down-arrow.gif" className="scrollDown" alt="Scroll Down" />

					</Container>
				</header>

				<Container id="projects" className="NavTargetBlock">
					<Container>
						<Row>
							<Col className="anchorPadTop">
								<h3>Projects:</h3>
							</Col>
						</Row>
					</Container>


					<Container className="contAlt" style={{marginTop:20, borderColor:"darkgray"}}>
						<Row>
							<Col>
								<h4 id="3dwellbore">3D Wellbore Visualization</h4>
								<Container>
									<Row>
										<Col md="5" style={{}}>
											<div>
												<div className="embed-responsive embed-responsive-1by1">
													<iframe className="embed-responsive-item"
														style={{}}
														scrolling="no"
														title="3dwellbore.com"
														src="https://3dwellbore.com/demo?hidemenu=true">
													</iframe>
												</div>
												{/*DisableWellbore===false &&
													<div className="wellboreOverlay">xxx</div>
												*/}
											</div>
											<p>Click &amp; drag, Zoom, etc.</p>
										</Col>
										<Col md="7">
											<p className="text-justify"><b>Overview:</b> Wellbores are crooked. A lot of fancy math and sensing goes into determining how those wellbores "look", when we can never actually see them physically. <a href="https://3dwellbore.com/demo" target="_blank" rel="noopener noreferrer">3dwellbore.com</a> allows anyone to take the surveyed data from a spreadsheet, and view an interactive plot of the wellbore. This helps engineers understand the mechanics of the well.</p>
											<p className="text-justify"><b>Architecture:</b> The plot was built using JavaScript and WebGL so that it would be easily accessible from corporate environments (i.e. no software to install). This project also uses a backend database to save the wellbore and markers so the user can revisit the page via an emailed link. Additionally, a collaborative service utilizing websockets synchronizes the view so multiple individuals can view the same model and view. A technical paper geared towards engineers entitled "<a href="https://onepetro.org/journal-paper/SPE-174024-PA" target="_blank" rel="noopener noreferrer">Interactive Web-Based 3D Wellbore Viewer Enables Collaborative Analysis</a>" was published in the SPE Journal of Production &amp; Operations (available on request). See the <a href="#writing">Technical Writing section below.</a></p>


											<Container>
												<Row>
													<Col md="7">
														<p className="text-justify">
															<b>Virtual Reality</b>: The above plot was extended to utilize the <a href="https://arvr.google.com/cardboard/" target="_blank" rel="noopener noreferrer">Google Cardboard</a> concept to further immerse the user and provide entertaining navigation of their wellbores. More information on the VR capabilities can be found <a href="https://3dwellbore.com/vr" target="_blank" rel="noopener noreferrer">here</a>.
														</p>
													</Col>
													<Col md="5">
														<Image src="images/vr-tools.png" alt="VR" />
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>
								<p>
									<a href="https://3dwellbore.com/help" target="_blank" rel="noopener noreferrer"><i className="fa fa-info-circle"></i></a>&nbsp;
									<a href="https://3dwellbore.com/help" target="_blank" rel="noopener noreferrer">https://3dwellbore.com/help</a>
								</p>
								<Container style={{marginTop:20}}>
									<Row>
										<Col md="7" className="col-sm">
											<Container>
												<Row style={{paddingTop:10}}>
													<Col md="auto">
														<a href="https://en.wikipedia.org/wiki/There_Will_Be_Blood" target="_blank" rel="noopener noreferrer"><Image src="images/there-will-be-blood-thumb.png" thumbnail /></a>
													</Col>
													<Col>
														<p className="text-justify" style={{paddingTop:20}}>
															You might recall this scene from 2007 movie "<a href="https://en.wikipedia.org/wiki/There_Will_Be_Blood" target="_blank" rel="noopener noreferrer">There Will Be Blood</a>". The interactive plot above is what Daniel Day-Lewis is talking about here.<br/>
															<span style={{fontSize:"smaller"}}>(Note: the green line in the plot above is the "straw")</span>
														</p>
														{/*
														<Image src="public/images/i-drink-your-milkshake-gif-3.gif" thumbnail />
														<p>I drink your milkshake!</p>
														*/}
													</Col>
												</Row>
											</Container>
										</Col>
										<Col md="5" className="col-sm">
											<div className="embed-responsive embed-responsive-16by9">
												<iframe className="embed-responsive-item" title="I drink your milkshake" src="https://www.youtube.com/embed/s_hFTR6qyEo" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
											</div>
										</Col>
									</Row>
								</Container>


								<Container style={{marginTop:20}}>
									<Row>
										<Col className="col-sm" style={{border:"solid 1px lightgray", padding:10}}>

											<a href="https://3dwellbore.com/demo" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
												<i className="fa fa-link" style={{fontSize:16}}></i>
											</a>&nbsp;
											<a href="https://3dwellbore.com/demo" target="_blank" rel="noopener noreferrer">https://3dwellbore.com/demo</a>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>

					<div id="pumpreporter" className="anchorPadTop" />
					<Container className="contNormal" style={{marginTop:100, borderColor:"darkgray"}}>
						<Row>
							<Col>
								<h4>Full Stack Design</h4>
								<Container>
									<Row>
										<Col className="text-justify">
											<p><b>pumpreporter<sup>®</sup> v5</b>: Full stack design is a bit of a buzz phrase. This project utilizes a custom board to interface with industrial controllers. The embedded firmware reads data from the controller and stores key parameters on a SD card. A custom iOS/Android app connects over Bluetooth to view live parameters and retrieve data stored on the SD card. The app then transmits that data to a server where it is permanently archived and available to other users of the same app.</p>
											<p>To us, "full stack" development means everything from the circuit board, firmware, phone app, and up through the server &amp; database architecture. These layers were all developed and implemented in-house. In addition, the prototype boards were assembled along with a 3D printed enclosure. This was all done in-house. This allows us to speed up the development cycle by reducing dependence on external 3<sup>rd</sup> party service providers. We can do it all, and out-source only when needed.</p>
										</Col>
										<Col md="auto">
											<Image src="images/projects/pumpreporter/pumpreporter_v5_circuit.png" width={200} thumbnail />
											<p className="text-justify"> </p>
										</Col>
									</Row>
									<Row>
										<Col>
											<p>This app is a custom implementation for this particular project and is not widely available on the app store (Apple or Google).</p>
										</Col>
									</Row>
								</Container>
								<Container>
									<Row>
										<Col md="9">
											<Container>
												<Row>
													<Col md="3">
														<Image src="images/projects/pumpreporter/2020-03-19 13.58.55.png" width={200} thumbnail />
													</Col>
													<Col md="3">
														<Image src="images/projects/pumpreporter/2020-02-25 14.49.12.png" width={200} thumbnail />
													</Col>
													<Col md="3">
														<Image src="images/projects/pumpreporter/2020-04-06 16.18.14.png" width={200} thumbnail />
													</Col>
													<Col md="3">
														<Image src="images/projects/pumpreporter/2020-03-19 13.59.15.png" width={200} thumbnail />
													</Col>
												</Row>
											</Container>
										</Col>
										<Col md="3">
											<Image src="images/projects/pumpreporter/pumpreporter_v5.jpg" width={200} thumbnail />
											<p style={{fontSize:11.5, alignContent:"center"}}>New version is small &amp; easy to install.</p>
											<p className="text-justify">Please <a href="#contact">contact us</a> for demo access to this app and for further details.</p>
										</Col>
									</Row>
								</Container>

								<hr/>

								<Container style={{marginTop:20}}>
									<Row>
										<Col>
											<Container>
												<Row>
													<Col md="8">
														<p className="text-justify"><b>pumpreporter<sup>®</sup> v4</b>: This project was originally a Linux based IIoT platform that utilizes WiFi for local connections and cellular/Ethernet for remote connections. While this product is still available, cost and use-case drove the change to a Bluetooth interface with lighter footprint hardware.</p>
														<p className="text-justify">Continuous development and improvement enabled this project to pivot. When an alternate business model was identified that is underserved in this particular market segment, the project evolved accordingly.</p>
													</Col>
													<Col md="4">
														<Image src="images/projects/pumpreporter/PR_Blank_Isometric.Transparent.png" alt="pumpreporter v4" width={250} />
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>

								<Container>
									<Row>
										<Col>
											<Container>
												<Row>
													<Col md="4">
														<Image src="images/projects/pumpreporter/pumporeporter_v4.jpg" width={400} thumbnail />&nbsp;
													</Col>
													<Col md="8">
														<p className="text-justify">The original iteration was entirely web based, providing the user a robust HTML 5 interface to view and interact with the data onsite. Because this hardware was an IIoT device, the same interface could be accessed remotely. Security and replication were implemented to minimize the costly data charges associated with M2M cellular or satellite connections.</p>
														<p className="text-justify">Hardware versions 1-3 were prototypes exploring different hardware platforms. Versions 1-4 were all Linux based and so the code evolved seamlessly from prototype to product phases. The switch to a proper embedded processor in version 5 necessitated a substantial rewrite of the codebase. The core components of communicating with the industrial controller were utilized in the new firmware for version 5.</p>
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>

					<div id="rigdyno" className="anchorPadTop" />
					<Container className="contAlt" style={{marginTop:100, borderColor:"darkgray"}}>
						<Row>
							<Col>
								<h4>R &amp; D Prototyping</h4>
								<Container>
									<Row>
										<Col>
											<p className="text-justify">The RigDyno<sup>™</sup> is a wansco project and is patent pending. It is basically a wireless sensor and data logger designed for a very specific and difficult task. Please <a href="#contact">contact us</a> for a demo and further details.</p>
											<p className="text-justify">In order to perform some advanced mathematical modeling, real world measurements are required. Unfortunately the process and environment in which the measurements are to be taken is hazardous and a suitable device for gathering the data is not available off the shelf. This project created a high frequency data logger for the required sensing components. It logs data on-board at high frequency, but also provides a Bluetooth interface so the operator can view key real-time parameters and check system status while the device is moving in the hazardous environment.</p>
											<p style={{alignContent:"center"}}>
												<Image src="images/projects/rigdyno/rigdyno-render.png" alt="Circuit Board Rendering" width={500} thumbnail />
											</p>
										</Col>
										<Col md="auto">
											<Image src="images/projects/rigdyno/rigdyno_assembled.png" alt="Assembled" width={300} thumbnail />
											<p className="text-justify"> </p>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>

				</Container>



				<Container id="technologies" className="NavTargetBlock">
					<Container>
						<Row>
							<Col className="anchorPadTop">
								<h3>Technologies:</h3>
							</Col>
						</Row>
					</Container>

					<Container className="contNormal" style={{borderColor:"darkgray"}}>
						<Row>
							<Col>
								<h4>Core Competencies:</h4>
								<Container className="contNormal" style={{borderStyle:"solid", borderColor:"darkgray"}}>
									<Row>
										<Col>
											<b>Programming Languages:</b>
											<p>C, C++, C#, Python, Java, JavaScript/Typescript, etc.</p>
											<p className="text-justify">In addition to the above languages, we are familiar with a plethora of frameworks and build tools. Here is a partial list: XCode, Android Development Studio, Jupyter, Visual Studio, Eclipse, TI Code Composer Studio, Plain Old Text Editor. React, Angular, ThreeJS, D3.js, Vue.js, and much more.</p>
										</Col>
									</Row>
								</Container>

								<Container className="contAlt">
									<Row>
										<Col>
											<b>Database:</b>
											<p>SQL Server, SQLite, MongoDB, DynamoDB (AWS), etc.</p>
											<p className="text-justify">Database design and maintenance is an on-going process. The technologies listed above are active projects that are under daily use, and represent a good cross-section of available platforms. Our database programming and management skills extend far beyond the listed platforms.</p>
										</Col>
									</Row>
								</Container>

								<Container className="contNormal">
									<Row>
										<Col>
											<b>Android/iOS:</b>
											<Container>
												<Row>
													<Col md="10">
														<p className="text-justify">React Native, Ionic/Cordova. Lower level platform-specific development is available, but generally the ease of a cross-platform development framework outweighs the benefits of platform specific code.</p>
														<p className="text-justify">Our application development projects are highly targeted, and due to confidentiality, these apps are not widely available on the app stores. In many cases they require our custom developed hardware to interact with. Please <a href="#contact">contact us</a> for a demo invite to try these apps.</p>
													</Col>
													<Col md="2">
														<Image height={64} alt="Android/iOS" src="images/android_ios.png" />
													</Col>
												</Row>
											</Container>
										</Col>
										<Col md="auto">
										</Col>
									</Row>
								</Container>

								<Container className="contAlt">
									<Row>
										<Col>
											<b>AWS/Cloud:</b>
											<Container>
												<Row>
													<Col md="3">
														<Image height={64} alt="Cloud" src="images/azure-aws-google-cloud-platforms.png" />
													</Col>
													<Col md="9">
														<p className="text-justify">EC2, S3, DynamoDB, Cognito, CloudFormation, Lambda, CodeCommit (git), CloudFront, etc. We use AWS extensively, but we are also familiar with Asure and Google platforms. </p>
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>

								<Container className="contNormal">
									<Row>
										<Col>
											<b>Firmware development:</b>
											<Container className="contNormal">
												<Row>
													<Col md="1">
														<Image src="images/ti.png" alt="TI" />
													</Col>
													<Col md="10">
														<p>TI (Texas Instruments), particularly the CC13xx/CC26xx (Arm<sup>®</sup>-based SimpleLink<sup>™</sup>) line of wireless processors, ESP32, PIC, STM32, etc.</p>
													</Col>
													<Col md="1">
														<Image src="images/ESP32-S2-l.png" alt="ESP32" />
													</Col>
												</Row>
											</Container>
											<p>Real Time Operating Systems: TIROTS &amp; FreeRTOS (see above processors)</p>
											<p>We can do Arduino projects, if needed, but would prefer to get a bit closer to the metal. There are other platforms (above) that offer a better defined path to commercial productization.</p>
											<p>SPI, I2C, BLE, WiFi, Serial (RS232/422/485), Industrial Protocols (Modbus, SNP, FieldBus, etc.)</p>
											<p>Accelerometer, gyro, magnetometer, load-cells, barometric pressure, data logging. See also Circuit Design.</p>
										</Col>
									</Row>
								</Container>

								<Container className="contAlt">
									<Row>
										<Col>
											<b>Linux:</b>
											<Container>
												<Row>
													<Col md="11">
														<p className="text-justify">Everything from low-level device drivers, to system administration. Apache, Nginx, etc. Primarily Debian based systems but have worked with other Linux distributions. We also have extensive knowledge of the OpenWRT project for network connected embedded Linux applications.</p>
														<p>Extensive experience in OSX and Windows, but those are pretty self explanitory.</p>
													</Col>
													<Col md="1">
														<Image height={64} alt="Linux" src="images/linux_penguin.png" />
													</Col>
												</Row>
											</Container>
										</Col>
									</Row>
								</Container>

								<Container className="contNormal">
									<Row>
										<Col>
											<b>IoT/IIoT:</b>
											<p>Experience developing internet connected devices. Provisioning and maintaining on-network devices and applications.</p>
											<p>For an example of a fully developed IIoT product, please see the <a href="#pumpreporter">pumpreporter</a><sup>®</sup> above</p>
										</Col>
									</Row>
								</Container>

								<Container className="contAlt">
									<Row>
										<Col>
											<b>Circuit Design:</b>
											<p className="text-justify">While developing industrial sensing and data applications, we have developed a number of circuit boards. More details are available on request as some of them are working their way through the patent system. Our preferred design tool is KiCad, but have also used Eagle, Orcad/Cadence, and Altium.</p>
										</Col>
									</Row>
								</Container>

								<Container className="contNormal">
									<Row>
										<Col>
											<b>Mechanical Engineering:</b>
											<p>Proficient in Solidworks. Primarily a means to fully utilize 3D printing and laser cutting tools for rapid prototyping.</p>
											<p>We have worked adjacent to Oil &amp; Gas for some time and have consulted on several mechanical designs and analysis.</p>
										</Col>
									</Row>
								</Container>

								<Container className="contAlt">
									<Row>
										<Col md="8" className="text-justify">
											<b>In-house Capabilities:</b>
											<p>Electronics Assembly: Low volume assembly of prototype SMD boards.</p>
											<p>Laser cutting &amp; engraving: Small plastic components</p>
											<p>
												3D printing: PLA, ABS, Nylon, etc.<br/>
												High temperature materials: PEEK, ULTEM.
											</p>
											<p>You can see how we put all of these to use in the <a href="#rigdyno">RigDyno</a><sup>™</sup> project above.</p>
											</Col>
										<Col md="4">
											<div className="embed-responsive embed-responsive-16by9">
												<iframe className="embed-responsive-item" title="3D Print" src="https://www.youtube.com/embed/qmwh5G_KrHQ" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
											</div>
										</Col>
									</Row>
								</Container>
								</Col>
						</Row>
					</Container>

				</Container>




				<Container id="about" className="NavTargetBlock">
					<Container>
						<Row>
							<Col className="anchorPadTop">
								<h3>About Us:</h3>
							</Col>
						</Row>
					</Container>
					<Container className="contAlt" style={{marginTop:10, borderColor:"darkgray"}}>
						<Row>
							<Col>
								<p className="text-justify">Wansco, established 2005, provides a wide range of services; from programming and systems design to training and technical writing. We strive to provide a complete array of support and resources for your most technical requirements. You can see some of our internal <a href="#projects">projects</a> above. There are many more, but we strictly respect customer confidentiality. Please feel free to <a href="#contact">contact us</a> and discuss your project.</p>
							</Col>
						</Row>
					</Container>

					<Container className="contNormal" style={{marginTop:20, borderColor:"darkgray"}}>
						<Row>
							<Col>
								<h4 id="writing">Technical Writing:</h4>
								<Container className="contNormal">
									<Row>
										<Col md="6">
											<p>
												<b>Patents:</b>&nbsp;
												<a href="https://patents.google.com/patent/US10550673B2" target="_blank" rel="noopener noreferrer">US 10,550,673</a>,&nbsp;
												<a href="https://patents.google.com/patent/US11525351B2" target="_blank" rel="noopener noreferrer">US 11,525,351</a>,&nbsp;
												<a href="https://patents.google.com/patent/US11560783B2" target="_blank" rel="noopener noreferrer">US 11,560,783</a>,&nbsp;
												<a href="https://patents.google.com/patent/US11814948B2" target="_blank" rel="noopener noreferrer">US 11,814,948</a>
											</p>
											<p className="text-justify">Several other patent applications are in progress. I can provide much more information on request. Patent applications are public record, but on-going development may yield further patentable content.</p>
										</Col>
										<Col md="6">
											<p><b>Papers:</b> <a href="https://onepetro.org/search?q=author%3A%22Phillips%2C+Walter%22" target="_blank" rel="noopener noreferrer">https://onepetro.org</a> <span className="smallText">(Papers &amp; presentations available on request)</span></p>
											<p className="text-justify">Technical writing skills are a key component to effective communication. The ability to convey an abstract concept to a wide audience is our core competency.</p>
										</Col>
									</Row>
								</Container>

								<Container className="contAlt">
									<Row>
										<Col md="4">
											<p>
												<a href="https://onepetro.org/journal-paper/SPE-174024-PA" target="_blank" rel="noopener noreferrer">Interactive Web-Based 3D Wellbore Viewer Enables Collaborative Analysis</a>
											</p>
										</Col>
										<Col md="8">
											<p className="text-justify">This is the technical paper presented in conjunction with the 3D wellbore viewer discussed <a href="#3dwellbore">above</a>. This contains a high level technology overview, but its primary target audience was production engineers and so it is focused more on the use of the tool than the underlying implementation. Still, it provides a good discussion of that underlying architecture.</p>
										</Col>
									</Row>
								</Container>
								<Container className="contNormal">
									<Row>
										<Col md="4">
											<p>
												<a href="https://onepetro.org/conference-paper/SPE-190920-MS" target="_blank" rel="noopener noreferrer">Visualizing Rod Design and Analysis Through the Wave Equation</a>
											</p>
										</Col>
										<Col md="8">
											<p className="text-justify">This is a highly focused exploration of some advanced mathematical models used for calculating conditions in a dynamic system. The dimensions in this paper are abstract and provide a visualization of these nuanced concepts. The plots provided in the paper are constructed from non-spatial dimensions, but portrayed in a physical and intuitive plot.</p>

											<p>A high level video illustrating the visuals that loosely follows this paper can be found <a href="#presentations">below</a>.</p>
										</Col>
									</Row>
								</Container>
								<Container className="contAlt">
									<Row>
										<Col md="4">
											<p>
												<a href="https://onepetro.org/conference-paper/SPE-171351-MS" target="_blank" rel="noopener noreferrer">Testing of Hydraulic Tubing Anchors</a>
											</p>
										</Col>
										<Col md="8">
											<p className="text-justify">This paper is a scientific experimentation of a mechanical device. The underlying data for this paper was gathered through an automated process involving a number of sensors.</p>
										</Col>
									</Row>
								</Container>
								<Container className="contNormal">
									<Row>
										<Col md="4">
											<p>
												<a href="https://onepetro.org/conference-paper/SPE-165022-MS" target="_blank" rel="noopener noreferrer">Improving the Reliability and Maintenance Costs of Hydraulically Actuated Sucker Rod Pumping Systems</a>
											</p>
										</Col>
										<Col md="8">
											<p className="text-justify">This paper explores a wide range of improvements to a complex industrial system.</p>
										</Col>
									</Row>
								</Container>

							</Col>
						</Row>

						<Row style={{marginTop:100}}>
							<Col>
								<h4 id="presentations">Presentations &amp; Training:</h4>
								<Container className="contNormal">
									<Row>
										<Col md="8">
											<p className="text-justify">Presentations and technical training are integral parts of the product life-cycle. In addition to developing complex systems, we strive to communicate those developments in interactive and informative ways.</p>
										</Col>
										<Col md="4">
											<div className="embed-responsive embed-responsive-16by9">
												<iframe className="embed-responsive-item" title="3D Dyno Analysis" src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6500809444140683264?compact=1" allowFullScreen={true}></iframe>
											</div>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>

					<Container className="contAlt" style={{marginTop:100, borderColor:"darkgray"}}>
						<Row>
							<Col md="8" className="text-justify">
								<p><b><a href="https://en.wikipedia.org/wiki/DARPA_Grand_Challenge" target="_blank" rel="noopener noreferrer">DARPA Grand Challenge</a></b> – It's been a while, but this project was a pioneering series of events in the world of autonomous vehicles. We were the only team to compete in all 3 events, using the same vehicle. Despite not prevailing, we were competitive, especially considering the substantial resources available to the leading teams. I’m 2nd on the left:</p>
								<p className="smallText text-truncate"><a href="https://archive.darpa.mil/grandchallenge05/Team_Applicants/Axion_Racing.html" target="_blank" rel="noopener noreferrer">https://archive.darpa.mil/grandchallenge05/Team_Applicants/Axion_Racing.html</a></p>
							</Col>
							<Col md="4">
								<div className="embed-responsive embed-responsive-16by9">
									<iframe className="embed-responsive-item" title="Axion Racing" src="https://www.youtube.com/embed/JKVwYfrPRrU" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
								</div>
							</Col>
						</Row>
					</Container>
				</Container>


				<Container id="contact" className="NavTargetBlock">
					<Container>
						<Row>
							<Col className="anchorPadTop">
								<h4>Contact</h4>
								<Container>
									<Row>
										<Col className="col-sm" style={{border:"solid 1px", padding:10}}>
											<a href="mailto:info@wansco.com?subject=Wansco Info" style={{textDecoration:"none", color:"black"}}>
												<i className="fa fa-envelope" style={{fontSize:32}}></i>
											</a>
											<br/>
											<a href="mailto:info@wansco.com?subject=Wansco Info">info@wansco.com</a>
											<noscript>info (at) wansco dot com</noscript>
										</Col>
										<Col className="col-sm" style={{border:"solid 1px", padding:10}}>
											<a href="https://www.linkedin.com/in/wansco/" target="_blank" rel="noopener noreferrer"><img height="32" alt="email" src="images/LI-In-Bug.png" /></a><br/>
											<a href="https://www.linkedin.com/in/wansco/" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/wansco/</a>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>

					<Container className="contAlt" >
						<Row>
							<Col className="">
								<p>Walter Phillips</p>
								<p>Masters in Computer Science, University of California, San Diego</p>
							</Col>
						</Row>
					</Container>

					<Container style={{marginTop:500}}></Container>
					<p className="copyrightBlock">©WANSCO, LLC 2005-2020</p>
				</Container>
			</div>
		</div>
	);
}

export default App;
